import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import OTPlannerHeader from './OTPlannerHeader';
import OTPlannerBlock from './OTPlannerBlock';
import OTLogo from './OTLogo';
import { hexColorWithOpacity } from '../utilities/OTColors';
import {
  themeColor, topGradientColor, numberOfAchievableRows, numberOfDaysInAWeek,
} from '../Constants';
import {
  clearAchievables, clearRoadmap, clearWeight, updateRoadmap,
} from '../reducers/roadmapSlice';
import OTWeightTracker from './OTWeightTracker';
import { selectRoadmap } from '../selectors/roadmap';

const PlannerHolder = styled.div`
  display:flex;
  z-index:100;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
`;

const BackgroundImageUrl = 'https://worldstrides.com/wp-content/uploads/2015/07/iStock_000061296808_Large-1.jpg';
const Background = styled.div`
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #cccccc;
  background-image: url(${BackgroundImageUrl});
`;

const BackgroundGradient = styled.div`
  height: 100vh;
  z-index:0;
  width: 100vw;
  top: 0;
  position: fixed;
  background: linear-gradient(${hexColorWithOpacity(topGradientColor, 0.95)}, ${hexColorWithOpacity(topGradientColor, 0.05)} 30%);
`;

// const Notes = styled.div`
//   z-index: 1000;
//   border-radius: 12px;
//   background-color: ${hexColorWithOpacity('#ffffff', 0.5)};
//   height: 200px;
//   margin-left: auto;
//   margin-right: auto;
//   width: 1216px;
//   border: 3px solid ${themeColor};
//   font-size: 20px;
//   color: ${themeColor};
//   text-align: left;
//   font-family: 'Avenir', sans-serif;
// `;

const TitleHolder = styled.div`
  min-width:1000px;
  width: 1000px;
  margin: 0 auto;
  padding: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
`;
// https://isotropic.co/hex-color-to-css-filter/
// const Logo = styled.img`
//   height: 65px;
//   float:left;
//   margin-top: 20px;
//   margin-left: 20px;
//   filter: invert(17%) sepia(66%) saturate(1287%) hue-rotate(83deg) brightness(95%) contrast(97%);
// `;

const Quote = styled.div`
  font-family: 'Avenir', sans-serif;
  color: ${themeColor};
  width: 800px;
  margin-left: 20px;
  font-size: 18px;
  display: inline-block;
`;

const Author = styled.span`
  font-family: 'Avenir', sans-serif;
  color: ${hexColorWithOpacity(themeColor, 0.75)};
  font-size: 18px;
`;

const ButtonPanel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const ClearButton = styled.div`
  border-radius: 6px;
  color: ${themeColor};
  font-weight:bold;
  cursor: pointer;
  font-family: Avenir, sans-serif;
  background-color: ${hexColorWithOpacity('#ffffff', 0.6)};
  backdrop-filter: blur(2px);
  padding: 10px;
  margin: 0 10px;
  text-align: center;
`;

const ReformattingDiv = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

// function useWindowSize() {
//   const [size, setSize] = useState([0, 0]);
//   useLayoutEffect(() => {
//     function updateSize() {
//       setSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateSize);
//     updateSize();
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);
//   return size;
// }

function OTPlanner() {
  useEffect(() => {
  }, []);

  const roadmap = useSelector(selectRoadmap);
  const dispatch = useDispatch();

  useEffect(() => {
    if (roadmap[0].length !== numberOfAchievableRows) {
      console.log('Data Healing');
      const rowDiff = numberOfAchievableRows - roadmap[0].length;
      if (rowDiff > 0) {
        const updatedRoadmap = JSON.parse(JSON.stringify(roadmap));
        for (let i = 0; i < rowDiff; i += 1) {
          const newRow = Array(numberOfDaysInAWeek).fill(0);
          updatedRoadmap[0].push(newRow);
          updatedRoadmap[1].push(newRow);
          updatedRoadmap[2].push(newRow);
          updatedRoadmap[3].push(newRow);
          dispatch(updateRoadmap({ roadmap: updatedRoadmap }));
        }
      }
    }
  }, [roadmap.length]);

  const dateSeedString = '2024-07-15T00:00:00';
  const monday1 = new Date(dateSeedString);
  const monday2 = new Date(dateSeedString);
  const monday3 = new Date(dateSeedString);
  const monday4 = new Date(dateSeedString);

  monday2.setDate(monday2.getDate() + 7);
  monday3.setDate(monday3.getDate() + 14);
  monday4.setDate(monday4.getDate() + 21);

  const headerWidth = 210;
  const blockWidth = 220;

  if (roadmap[0].length !== numberOfAchievableRows) {
    return <ReformattingDiv>🧼 Reformatting Persistent Storage</ReformattingDiv>;
  }

  return (
    <Background>
      <BackgroundGradient>
        <TitleHolder>
          <OTLogo color={themeColor} />
          <Quote>
            <b>
              &quot;It’s not that I’m so smart, it’s just that I stay with problems longer.
              <br />
              <Author> - Albert Einstein</Author>
            </b>
          </Quote>
        </TitleHolder>
        <PlannerHolder>
          <OTPlannerHeader nRows={9} width={headerWidth} />
          <OTPlannerBlock
            nRows={numberOfAchievableRows}
            width={blockWidth}
            monday={monday1}
            week={1}
          />
          <OTPlannerBlock
            nRows={numberOfAchievableRows}
            width={blockWidth}
            monday={monday2}
            week={2}
          />
          <OTPlannerBlock
            nRows={numberOfAchievableRows}
            width={blockWidth}
            monday={monday3}
            week={3}
          />
          <OTPlannerBlock
            nRows={numberOfAchievableRows}
            width={blockWidth}
            monday={monday4}
            week={4}
          />
        </PlannerHolder>
        <OTWeightTracker width={500} height={240} />
        <ButtonPanel>
          <ClearButton
            onClick={() => {
              if (window.confirm('Are you sure want to clear your achievables column?')) {
                dispatch(clearAchievables());
              }
            }}
          >
            Clear Achievables
          </ClearButton>
          <ClearButton
            onClick={() => {
              if (window.confirm('Are you sure want to clear your roadmap check data?')) {
                dispatch(clearRoadmap());
              }
            }}
          >
            Clear Roadmap
          </ClearButton>
          {/* <ClearButton */}
          {/*  onClick={() => { */}
          {/*    dispatch(clearAll()); */}
          {/*  }} */}
          {/* > */}
          {/*  Clear All */}
          {/* </ClearButton> */}
          <ClearButton
            onClick={() => {
              if (window.confirm('Are you sure want to clear your weight log?')) {
                dispatch(clearWeight());
              }
            }}
          >
            Clear Weight Log
          </ClearButton>
        </ButtonPanel>
        {/* <Notes> */}
        {/*  <div style={{ padding: 20 }}> */}
        {/*    <b>Notes</b> */}
        {/*  </div> */}
        {/* </Notes> */}
      </BackgroundGradient>
    </Background>
  );
}

export default OTPlanner;
